import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { IconButton, Box } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import RouteIcon from "@mui/icons-material/Route";
import AddIcon from "@mui/icons-material/Add";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupIcon from "@mui/icons-material/Group";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import SummarizeSharpIcon from "@mui/icons-material/SummarizeSharp";
import FeedSharpIcon from "@mui/icons-material/FeedSharp";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import StorefrontIcon from "@mui/icons-material/Storefront";
import QrCodeIcon from "@mui/icons-material/QrCode";
import BusinessIcon from "@mui/icons-material/Business";
import BusAlertIcon from "@mui/icons-material/BusAlert";
import StoreIcon from "@mui/icons-material/Store";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import logo from "../assets/GIF.gif";
import { NavLink } from "react-router-dom";
import { user } from "../services/login";
import { FormatListBulleted } from "@mui/icons-material";

// Fee Icons
import PaymentsIcon from '@mui/icons-material/Payments';

const drawerWidth = 340;
const activeColor = "#4b26d1";
const normalColor = "white";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const SideBar = ({ handleDrawerClose, open }) => {
  const theme = useTheme();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },  
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader sx={{ pl: 0 }}>
        <Box>
          <img id="nav-img" src={logo} alt="" />
        </Box>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      {user.role === "oro_admin" && (
        <>
          <List>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/transactionsIndex"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <SummarizeSharpIcon />
                </ListItemIcon>
                Transaction
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/terminals"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <RouteIcon />
                </ListItemIcon>
                Terminals
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/terminalsCreate"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                Create Terminal
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/routes"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AltRouteIcon />
                </ListItemIcon>
                Routes
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/createRoutes"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                Create Route
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/segments"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AltRouteIcon />
                </ListItemIcon>
                Segment Routes
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/createSegments"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                Create Segment Routes
              </ListItem>
            </NavLink>

            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/drivers"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AirlineSeatReclineExtraIcon />
                </ListItemIcon>
                Drivers
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/vehicles"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                Vehicles
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/vehicleManagementData"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <BusAlertIcon />
                </ListItemIcon>
                Vehicles Management Data
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/barrierGates"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <StoreIcon />
                </ListItemIcon>
                Barrier Gates
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/vendingMachineTransactions"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <SummarizeSharpIcon />
                </ListItemIcon>
                Vending Machine Transactions
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/vendingMachineAccounts"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <SummarizeSharpIcon />
                </ListItemIcon>
                Vending Machines
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/trips"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <RouteIcon />
                </ListItemIcon>
                Trips
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/admins"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                Admins
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/adminWallets"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AccountBalanceWalletIcon />
                </ListItemIcon>
                Admins Wallets
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/passengers"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                Passengers
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/walletsIndex"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AccountBalanceWalletIcon />
                </ListItemIcon>
                Passenger Wallets
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/passengerWalletHistory"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AccountBalanceWalletIcon />
                </ListItemIcon>
                Passenger Wallet History
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/passengerWalletTopupHistory"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AccountBalanceWalletIcon />
                </ListItemIcon>
                Passenger Wallet Topup History
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/passengerServicesWallets"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <GroupIcon />
                  <AccountBalanceWalletIcon />
                </ListItemIcon>
                Passenger Services Wallets
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/nonPTPDriversByPassenger"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AirlineSeatReclineExtraIcon />
                </ListItemIcon>
                Non PTP Drivers By Passenger
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/tracing/ptp"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <ManageSearchIcon />
                </ListItemIcon>
                PTP Passenger Tracing
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/tracing/non-ptp"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <ManageSearchIcon />
                </ListItemIcon>
                Non PTP Tracing
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/passengersAndDriversByTrip"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <EmojiPeopleIcon />
                  <AirlineSeatReclineExtraIcon />
                </ListItemIcon>
                Passengers And Drivers By Trip
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/servedPassengers"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <EmojiPeopleIcon />
                </ListItemIcon>
                Passengers Served By Driver
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/merchants"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <StorefrontIcon />
                </ListItemIcon>
                Merchants
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/merchantQRs"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <StorefrontIcon />
                  <QrCodeIcon />
                </ListItemIcon>
                Merchant QRs
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/changeTheme"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AppShortcutIcon />
                </ListItemIcon>
                Change App Color Theme
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/terminalManager/create"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                Create Terminal Manager
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/operator/create"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                Create Operator
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/merchant/create"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                Create Merchant
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/changePassengerDriver"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <ModeEditIcon />
                </ListItemIcon>
                Change Passenger Or Driver Password
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/insurances/PTPs"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                PTP Insurance
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/insurances/deliveries"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                Delivery Insurance
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/insurances/create"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AddIcon /> <BusinessIcon />
                </ListItemIcon>
                create
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              to="/validators"
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <SupervisedUserCircleIcon />
                </ListItemIcon>
                Validators
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              to="/vehicleTypes"
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <FormatListBulleted />
                </ListItemIcon>
                Vehicle Types
              </ListItem>
            </NavLink>
          </List>
        </>
      )}
      {user.role === "terminal_manager" && (
        <>
          <List>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/terminalTransactions"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <SummarizeSharpIcon />
                </ListItemIcon>
                Terminal Transactions
              </ListItem>
            </NavLink>
            <Divider />
            
            {/* Accreditation fee nav link */}
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/accreditationFees"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <PaymentsIcon />
                </ListItemIcon>
                Accreditation Fee
              </ListItem>
            </NavLink>

            {/* Accreditation fee nav link */}
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/monthlyFees"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <PaymentsIcon />
                </ListItemIcon>
                Membership Fee
              </ListItem>
            </NavLink>
            <Divider />

            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/dispatchFees"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <PaymentsIcon />
                </ListItemIcon>
                Dispatch Fee
              </ListItem>
            </NavLink>
            <Divider />

            {/* <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/ptpPassengerLogs"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <FeedSharpIcon />
                </ListItemIcon>
                PTP Passenger Logs
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/nonPtpPassengerLogs"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <FeedSharpIcon />
                </ListItemIcon>
                Non PTP Passenger Logs
              </ListItem>
            </NavLink> */}
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/routes"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AltRouteIcon />
                </ListItemIcon>
                Routes
              </ListItem>
            </NavLink>
            {/* <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/addTrip"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                Add Trip
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/trips"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <RouteIcon />
                </ListItemIcon>
                Trips
              </ListItem>
            </NavLink> */}
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/segments"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AltRouteIcon />
                </ListItemIcon>
                Segment Routes
              </ListItem>
            </NavLink>
            <Divider />
            {/* <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/tickets"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <FeedSharpIcon />
                </ListItemIcon>
                PTP Tickets
              </ListItem>
            </NavLink> */}
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/normalTickets"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <FeedSharpIcon />
                </ListItemIcon>
                Normal Tickets
              </ListItem>
            </NavLink>
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/nomralTicketUnuseds"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <FeedSharpIcon />
                </ListItemIcon>
                Unused Normal Tickets
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/vehicles/approved"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                Vehicles
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/barrierGates"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <StoreIcon />
                </ListItemIcon>
                Barrier Gates
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/vendingMachineTransactions"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <SummarizeSharpIcon />
                </ListItemIcon>
                Vending Machine Transactions
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/createTerminalStaff"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                Create Terminal Staff
              </ListItem>
            </NavLink>
            <NavLink
              to="/terminalStaffs"
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                Terminal Staffs
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              to="/createValidators"
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                Create Validator
              </ListItem>
            </NavLink>
            <NavLink
              to="/validators"
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <SupervisedUserCircleIcon />
                </ListItemIcon>
                Validators
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              to="/collectors"
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <SupervisedUserCircleIcon />
                </ListItemIcon>
                Collectors
              </ListItem>
            </NavLink>
            <NavLink
              to="/createCollectors"
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                Create Collector
              </ListItem>
            </NavLink>
            <NavLink
              to="/vehicleFeeInfo"
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <FeedSharpIcon />
                </ListItemIcon>
                Vehicle Fee Info
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              to="/operatorsRoutes"
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <SupervisedUserCircleIcon />
                  <RouteIcon />
                </ListItemIcon>
                Operators Routes
              </ListItem>
            </NavLink>
            <NavLink
              to="/createOperatorsRoutes"
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                Create Operators Routes
              </ListItem>
            </NavLink>
          </List>
        </>
      )}
      {user.role === "operator" && (
        <>
          <List>
            {/* <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/addTrip"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                Add Trip
              </ListItem>
            </NavLink>
            <NavLink
              to="/trips"
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <RouteIcon />
                </ListItemIcon>
                Trips
              </ListItem>
            </NavLink>
            <Divider /> */}
            <NavLink
              to="/createDrivers"
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                Create Driver
              </ListItem>
            </NavLink>
            <NavLink
              to="/drivers"
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AirlineSeatReclineExtraIcon />
                </ListItemIcon>
                Drivers
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              to="/createVehicles"
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                Register Vehicle
              </ListItem>
            </NavLink>
            <NavLink
              to="/vehicles"
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                Vehicles
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
              to="/normalTickets"
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <FeedSharpIcon />
                </ListItemIcon>
                Normal Tickets
              </ListItem>
            </NavLink>
            {/* <Divider />
            <NavLink
              to="/createValidators"
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                Create Validator
              </ListItem>
            </NavLink>
            <NavLink
              to="/validators"
              style={(isActive) => ({
                color: isActive ? normalColor : activeColor,
                backgroundColor: isActive ? activeColor : normalColor,
              })}
            >
              <ListItem button style={{ backgroundColor: "inherit" }}>
                <ListItemIcon>
                  <SupervisedUserCircleIcon />
                </ListItemIcon>
                Validators
              </ListItem>
            </NavLink> */}
          </List>
        </>
      )}
    </Drawer>
  );
};

export default SideBar;
