import "./App.css";
import { Route, Switch } from "react-router-dom";
import Login from "./views/Login";
import Admin from "./Admin";
import React, {Component} from 'react'

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/">
          <Admin />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
